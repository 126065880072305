import { Close, Delete } from '@rossum/ui/icons';
import { Box, Slide, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AddRowBelow } from '../../features/annotation-view/document-canvas/components/icons/AddRowBelow';
import { disableDatapointsActionsSelector } from '../../redux/modules/datapoints/selector';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { FooterLayout } from '../../types/user';
import ItemWrapper from './ItemWrapper';

const SELECTION_TEXT_MIN_WIDTH = 110;
const TOOLTIP_DELAY = 600;

type FooterSelectionPanelProps = {
  itemsCount: number;
  onDeleteSelected: () => void;
  onClearSelected: () => void;
  onAddTuple: () => void;
  footerLayout: FooterLayout;
};

export const FooterSelectionPanel = ({
  itemsCount,
  onDeleteSelected,
  onClearSelected,
  onAddTuple,
  footerLayout,
}: FooterSelectionPanelProps) => {
  const intl = useIntl();
  const showSelectionPanel = itemsCount > 0;
  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const disableActions = useSelector(disableDatapointsActionsSelector);

  return (
    <Slide direction="right" in={showSelectionPanel} mountOnEnter unmountOnExit>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        height="100%"
        sx={{
          position: 'absolute',
          top: theme => theme.spacing(1.5),
          left: theme =>
            footerLayout === 'one-table'
              ? theme.spacing(10.5)
              : theme.spacing(14),
          px: 2,
          minHeight: theme => theme.spacing(6.75),
          backgroundColor: theme => theme.palette.primary.main,
          color: theme => theme.palette.common.white,
          borderRadius: 2,
          zIndex: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{ display: 'inline-block', minWidth: SELECTION_TEXT_MIN_WIDTH }}
        >
          {intl.formatMessage(
            {
              id: 'components.documentValidation.footer.selectionPanel.selected',
            },
            {
              count: itemsCount,
              emphasize: (msg: ReactNode) => (
                <Box component="span" sx={{ fontWeight: 700 }}>
                  {msg}
                </Box>
              ),
            }
          )}
        </Typography>
        {showSelectionPanel && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            flexGrow={1}
            sx={{ px: 2.5 }}
          >
            <ItemWrapper
              Icon={Delete}
              label={intl.formatMessage({
                id: 'components.documentValidation.footer.selectionPanel.delete',
              })}
              onClick={() => onDeleteSelected()}
              disabled={disableActions}
            />
            {complexLineItemsEnabled ? (
              <ItemWrapper
                Icon={AddRowBelow}
                label={intl.formatMessage({
                  id: 'components.documentValidation.footer.selectionPanel.insertLine',
                })}
                onClick={onAddTuple}
                disabled={disableActions}
              />
            ) : null}
          </Stack>
        )}

        <Stack onClick={() => onClearSelected()}>
          <Tooltip
            title={intl.formatMessage({
              id: 'components.documentValidation.footer.selectionPanel.buttons.deselect',
            })}
            enterDelay={TOOLTIP_DELAY}
          >
            <Close
              sx={{
                opacity: 0.6,
                cursor: 'pointer',
                transition: 'opacity 0.2s',
                ':hover': { opacity: 1 },
              }}
              data-cy="cancel-selection-clear-icon"
            />
          </Tooltip>
        </Stack>
      </Stack>
    </Slide>
  );
};
