import {
  Autocomplete,
  CircularProgress,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from '@rossum/ui/material';
import { forwardRef, Ref, useState } from 'react';
import { useIntl } from 'react-intl';
import { Url } from '../../../types/basic';
import { VirtualizedListboxFixedSize } from '../../../ui/virtualized-listbox/VirtualizedListboxFixedSize';

type TokenOwnerSelectProps<U extends { url: Url; username: string }> = {
  allOrgUsers: U[];
  users: U[];
  value: Url | null;
  onChange: (values: Url | null) => void;
  loading?: boolean;
};

export const getUserName = <
  U extends {
    firstName?: string;
    lastName?: string;
    email?: string;
    username: string;
  },
>(
  user: U
): string => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.email) {
    return user.email;
  }

  return user.username;
};

const TokenOwnerSelect = forwardRef(
  <U extends { url: Url; username: string }>(
    {
      allOrgUsers,
      loading = false,
      value,
      onChange,
      users,
    }: TokenOwnerSelectProps<U>,
    ref: Ref<unknown>
  ) => {
    const [inputValue, setInputValue] = useState<string>('');

    const intl = useIntl();
    const selectedUserObject = allOrgUsers.find(({ url }) => url === value);

    return (
      <Autocomplete
        ref={ref}
        data-cy="extensions-users-select"
        slots={{
          paper: Paper,
        }}
        slotProps={{
          listbox: {
            component: VirtualizedListboxFixedSize,
          },
          paper: { elevation: 6 },
        }}
        size="small"
        options={users}
        getOptionLabel={user => getUserName(user)}
        value={selectedUserObject ?? null}
        onChange={(_e, newValue) => onChange(newValue?.url ?? null)}
        disabled={loading}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={intl.formatMessage({
              id: 'containers.settings.extensions.createExtension.tokenOwner.placeholder',
            })}
            slotProps={{
              inputLabel: { shrink: true, ...params.InputLabelProps },
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              },
            }}
          />
        )}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={(params, user) => (
          <ListItemButton component="li" {...params} key={user.url}>
            <ListItemText secondary={getUserName(user)} />
          </ListItemButton>
        )}
      />
    );
  }
);

export default TokenOwnerSelect;
