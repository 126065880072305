import {
  Autocomplete,
  autocompleteClasses,
  FilterOptionsState,
  Popper,
  styled,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from '@rossum/ui/material';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EnumOption } from '../../../../../types/schema';
import {
  RenderRowData,
  VirtualizedListBox,
} from './SidebarItemActiveEnumValue.VirtualizedListBox';

const CHARACTER_WIDTH = 9.1;
const ROW_PADDING_POSITIONS = 10;
const MAX_LIST_BOX_WIDTH = Infinity;

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const autocompleteStyles: SxProps<Theme> = {
  [`.${autocompleteClasses.clearIndicator}`]: { visibility: 'visible' },
};

const filterOptions = (
  options: Array<EnumOption>,
  { inputValue }: FilterOptionsState<EnumOption>
) => {
  return inputValue
    ? matchSorter(options, inputValue, {
        keys: [
          {
            threshold: matchSorter.rankings.CONTAINS,
            key: 'label',
          },
          {
            threshold: matchSorter.rankings.CONTAINS,
            key: option => option.value.toString(),
          },
        ],
      })
    : options;
};

type SidebarItemActiveValueProps = {
  value: EnumOption | undefined;
  onChange: (val: EnumOption | null) => void;
  options: EnumOption[];
  clearable: boolean;
  disabled: boolean;
  shouldAutoOpen: boolean;
};

export const SidebarItemActiveEnumValue = ({
  value,
  onChange,
  options,
  clearable,
  disabled,
  shouldAutoOpen,
}: SidebarItemActiveValueProps) => {
  const theme = useTheme();

  const handleChange = useCallback(
    (newValue: EnumOption | null) => {
      return onChange(newValue);
    },
    [onChange]
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (shouldAutoOpen && !disabled) {
      setOpen(true);
    }
  }, [disabled, shouldAutoOpen]);

  const [highlightedOption, setHighlightedOption] = useState<EnumOption | null>(
    null
  );

  const handleHighlighted = useCallback(
    (_: React.SyntheticEvent, newValue: EnumOption | null) => {
      setHighlightedOption(newValue);
    },
    []
  );

  const onKeyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (['Enter'].includes(e.key) && open) {
        e.preventDefault();
        e.stopPropagation();

        handleChange(highlightedOption);
        setOpen(false);
      }

      if (e.key === 'Tab' && open) {
        if (highlightedOption) {
          handleChange(highlightedOption);
        }
      }

      // TODO: Is this even needed? With the condition on `clearable` it was impossible to type ingo non-clearable dropdowns :thinking:
      if (['Delete', 'Backspace'].includes(e.key)) {
        e.stopPropagation();

        handleChange(null);
      }
    },
    [handleChange, open, highlightedOption]
  );

  const textFieldInputOnFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    event.target.select();
  };

  const autocompleteRef = useRef<HTMLElement | null>(null);

  const [listWidth, setListWidth] = useState(0);

  useEffect(() => {
    if (options) {
      const longestLabelWidth = options.reduce((maxWidthSoFar, option) => {
        if (!option) {
          return maxWidthSoFar;
        }
        const width =
          option.label.length * CHARACTER_WIDTH + ROW_PADDING_POSITIONS;
        return Math.max(maxWidthSoFar, width);
      }, 0);

      const inputWidth =
        autocompleteRef?.current?.getBoundingClientRect()?.width ?? 0;

      // at least input width
      setListWidth(
        Math.max(inputWidth, Math.min(MAX_LIST_BOX_WIDTH, longestLabelWidth))
      );
    }
  }, [options]);

  return (
    <Autocomplete
      ref={autocompleteRef}
      sx={autocompleteStyles}
      autoHighlight
      size="small"
      disabled={disabled}
      disableClearable={!clearable}
      disableListWrap
      options={options}
      autoComplete
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onHighlightChange={handleHighlighted}
      renderInput={params => {
        return (
          <TextField
            {...params}
            size="small"
            multiline
            maxRows={3}
            autoFocus
            onFocus={textFieldInputOnFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
              sx: {
                fontFeatureSettings: '"zero"',
                textarea: {
                  scrollbarColor: 'transparent transparent',
                  scrollbarWidth: 'none',
                },
              },
            }}
            inputProps={{
              ...params.inputProps,
              onKeyDown: onKeyDownHandler,
              value: params.inputProps.value || value?.label || '',
              style: {
                ...params.inputProps?.style,
                ...theme.typography.body2,
              },
            }}
          />
        );
      }}
      renderOption={(props, option, state) => {
        const tuple: RenderRowData = [
          props,
          option,
          state.index,
          state.inputValue,
        ] as const;
        // typecasting to make Autocomplete happy,
        // it is consumed in VirtualizedListBox and renderRow
        return tuple as React.ReactNode;
      }}
      filterOptions={filterOptions}
      value={value ?? null}
      onChange={(_, selected) => {
        handleChange(selected);
      }}
      slotProps={{
        paper: {
          sx: {
            fontFeatureSettings: '"zero"',
            width: listWidth,
            maxWidth: '80vw',
          },
        },
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={VirtualizedListBox}
    />
  );
};
