import { Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';
import { linebreak } from '../../../lib/formaterValues';

type Props = {
  icon: ReactNode;
  title: LocalizationKeys;
  text?: LocalizationKeys;
  inGraph?: boolean;
  inTable?: boolean;
};

const InfoPlaceholder = ({ title, text, icon, inGraph, inTable }: Props) => {
  const intl = useIntl();

  return (
    <Stack
      spacing={1}
      sx={{
        padding: '200px 0',
        alignItems: 'center',
        ...(inGraph && { padding: '0px 0px 80px 0px' }),
        ...(inTable && { padding: '80px 0px', margin: '0 auto' }),
      }}
    >
      <Stack
        sx={{
          backgroundColor: theme => theme.palette.grey[600],
          borderRadius: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          width: 48,
          height: 48,
        }}
      >
        {icon}
      </Stack>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {intl.formatMessage({ id: title })}
      </Typography>
      {text && (
        <Typography variant="body2">
          {intl.formatMessage({ id: text }, { linebreak })}
        </Typography>
      )}
    </Stack>
  );
};

export default InfoPlaceholder;
