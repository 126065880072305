import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@rossum/ui/icons';
import { Box } from '@rossum/ui/material';
import clsx from 'clsx';
import { CSSProperties } from 'react';
import styles from '../style.module.sass';

type Props = {
  value?: string;
  inFooter: boolean;
  style: CSSProperties | undefined;
};

const FakeSelect = ({ value, inFooter, style }: Props) => (
  <Box sx={{ fontFeatureSettings: '"zero"' }}>
    <div
      className={clsx(styles.FakeSelect, inFooter && styles.FakeSelectInFooter)}
      style={style}
    >
      <span className={styles.Value}>{value}</span>
      <span className={styles.Icon}>
        <KeyboardArrowDownIcon />
      </span>
    </div>
  </Box>
);

export default FakeSelect;
