import { Stack } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { customApiUrl } from '../../constants/config';
import { OverduePaymentsBanner } from '../../features/overdue-payments/OverduePaymentsBanner';
import { overduePaymentsSelector } from '../../features/overdue-payments/selectors/overduePaymentsSelector';
import {
  repeatedStatusSelector,
  showNetworkAlertSelector,
} from '../../redux/modules/ui/selectors';
import ChangeApiAlert from './components/ChangeApiAlert';
import NetworkAlert from './components/NetworkAlert';
import RepeatedStatusAlert from './components/RepeatedStatusAlert';

const Announcements = () => {
  const repeatedStatus = useSelector(repeatedStatusSelector);
  const showNetworkAlert = useSelector(showNetworkAlertSelector);
  const { isOverdue, messageKey, severity } = useSelector(
    overduePaymentsSelector
  );

  return (
    <Stack sx={{ zIndex: 100, position: 'relative' }}>
      {isOverdue && (
        <OverduePaymentsBanner messageKey={messageKey} severity={severity} />
      )}
      {!!customApiUrl && <ChangeApiAlert changedApiUrl={customApiUrl} />}
      {showNetworkAlert && <NetworkAlert />}
      {repeatedStatus && <RepeatedStatusAlert status={repeatedStatus} />}
    </Stack>
  );
};

export default Announcements;
