import { IconAlertTriangleFilled } from '@rossum/ui/icons/tabler';
import { Button, Stack, SvgIcon, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { boldText } from '../../../lib/formaterValues';

type ChangedApiAlertProps = {
  changedApiUrl: string;
};

const ChangedApiAlert = ({ changedApiUrl }: ChangedApiAlertProps) => {
  const intl = useIntl();

  const handleRemoveClearUrl = () => {
    localStorage.removeItem('customApiUrlValue');

    window.location.replace(`/`);
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme => theme.palette.error.light,
        py: 0.5,
        height: '32px',
      }}
    >
      <SvgIcon fontSize="small">
        <IconAlertTriangleFilled />
      </SvgIcon>
      <Typography variant="body2">
        {intl.formatMessage(
          {
            id: 'components.announcements.changeAPIAlert.text',
          },
          {
            changedApiUrl,
            boldText,
          }
        )}
      </Typography>

      <Button
        variant="text"
        size="small"
        onClick={() => handleRemoveClearUrl()}
        color="inherit"
      >
        {intl.formatMessage({
          id: `components.announcements.changeAPIAlert.clearButton`,
        })}
      </Button>
    </Stack>
  );
};

export default ChangedApiAlert;
