import { FieldError } from 'react-hook-form';
import { z } from 'zod';
import { httpErrorPreprocessor } from '../../../lib/httpErrorPreprocessor';

export const ruleErrorSchema = z
  .object({
    rule_actions: z
      .array(
        z.object({
          action: z
            .object({
              payload: z
                .record(
                  z.string(),
                  z.union([
                    // This is an usual response
                    z.array(z.string()),

                    // This is a hack to resolve sendEmail.to response
                    z
                      .record(z.string(), z.array(z.string()))
                      .transform(x => x['0']),
                  ])
                )
                .optional(),
            })
            .optional()
            .transform((actionError): FieldError[] | undefined => {
              const fieldErrors =
                actionError && actionError.payload
                  ? Object.entries(actionError.payload)
                  : undefined;

              return fieldErrors?.flatMap(([key, errors]) =>
                errors.map(e => ({
                  message: `${key}: ${e}`,
                  type: 'error',
                }))
              );
            }),
        })
      )
      .optional(),
  })
  // Poor man's snake to camel
  .transform(({ rule_actions }) => {
    return { ruleActions: rule_actions };
  });

export const ruleErrorParser = z.preprocess(
  httpErrorPreprocessor,
  ruleErrorSchema
);

export type RuleError = z.TypeOf<typeof ruleErrorParser>;
