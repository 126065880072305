import { SubdirectoryArrowRight } from '@rossum/ui/icons';
import {
  Box,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { constructDocumentUrl } from '../../../../lib/url';
import { dataDisabled } from '../../../../redux/modules/annotation/helpers';
import { ID } from '../../../../types/basic';
import Filename from '../../../../ui/filename/Filename';
import StatusChip from '../../../../ui/status/StatusChip';
import { disabledContainerStyles } from '../../../pricing/components/DisabledRow';
import { AttachmentT, isAnnotationDisabled } from './helpers';

type Props = {
  // Attachment with annotation present.
  attachment: AttachmentT & Pick<Required<AttachmentT>, 'annotation'>;
  userId: ID;
  isChild: boolean;
  onMouseEnter: (attachment: AttachmentT) => void;
  onMouseLeave: () => void;
};

const AnnotationAttachment = ({
  attachment,
  userId,
  isChild,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const intl = useIntl();
  const {
    annotation: { status, id },
  } = attachment;

  const statusTooltipTitle = intl.formatMessage(
    {
      id: `containers.annotationList.statuses.reviewing.tooltip`,
    },
    {
      modifier:
        attachment.annotationModifier?.username ??
        intl.formatMessage({
          id: 'containers.documents.unknownModifier',
        }),
    }
  );

  return (
    <ListItemButton
      disableRipple
      disableGutters
      sx={{
        py: 0,
        pl: 2,
        borderRadius: 1,
        ...(attachment.annotation.restrictedAccess
          ? disabledContainerStyles
          : {}),
      }}
    >
      {isChild && (
        <SubdirectoryArrowRight
          fontSize="small"
          sx={{ color: theme => theme.palette.text.secondary }}
        />
      )}
      <ListItemIcon sx={{ minWidth: 25, mr: 1 }}>
        {status !== 'purged' ? (
          <StatusChip
            status={status}
            isOwned={attachment.annotationModifier?.id === userId}
            tooltipTitle={status === 'reviewing' ? statusTooltipTitle : null}
            disabled={attachment.annotation.restrictedAccess}
          />
        ) : null}
      </ListItemIcon>
      <ListItemText sx={{ my: 0 }}>
        <Link
          // TODO isAnnotationDisabled cursor
          onClick={e => isAnnotationDisabled(status) && e.preventDefault()}
          target="_blank"
          href={constructDocumentUrl({ id })}
          rel="noreferrer"
          underline="none"
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Box
            onMouseEnter={() => onMouseEnter(attachment)}
            onMouseLeave={() => onMouseLeave()}
            sx={{ py: 1 }}
          >
            <Typography
              variant="body2"
              color="text.primary"
              whiteSpace="nowrap"
              component="div"
            >
              <Filename
                status={attachment.annotation.status}
                disabled={dataDisabled.includes(status)}
                originalFilename={attachment.document.originalFileName}
              />
            </Typography>
          </Box>
        </Link>
      </ListItemText>
    </ListItemButton>
  );
};

export { AnnotationAttachment };
