import React, { HTMLAttributes } from 'react';
import { createContext, forwardRef, ReactElement, useContext } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style,
  });
};

export interface VirtualizedListboxFixedSizeProps
  extends HTMLAttributes<HTMLElement> {
  itemSize?: number;
}

export const VirtualizedListboxFixedSize = forwardRef<
  HTMLDivElement,
  VirtualizedListboxFixedSizeProps
>(function ListboxComponent(props, ref) {
  const { children, itemSize = 40, ...other } = props;
  const itemData: ReactElement<unknown>[] = [];
  (children as ReactElement<unknown>[]).forEach(
    (
      item: ReactElement<unknown> & {
        children?: ReactElement<unknown>[];
      }
    ) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const itemCount = itemData.length;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemCount * itemSize;
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={getHeight()}
          width="100%"
          itemCount={itemCount}
          itemSize={itemSize}
          outerElementType={OuterElementType}
          innerElementType="ul"
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
