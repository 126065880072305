import { isFinite, isNaN, isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';

// Percentages (0 - 100) are limited to one decimal place)
export const limitPercentageDecimalPlaces = (num: number) =>
  parseFloat(num.toFixed(1));

// Float values (0 - 1) are limited to three decimal places
const limitFloatDecimalPlaces = (num: number) => parseFloat(num.toFixed(3));

const limitValue = (num: number) => Math.max(Math.min(num, 1), 0);

type Value = number | '' | null;
type HookArg = {
  value: Value;
  onChange: (value: number | null) => void;
  defaultValue: Value;
};

export const resolveValue = ({
  inputValue,
  defaultValue,
}: {
  inputValue: string;
  defaultValue: Value;
}) => {
  const numberValue =
    inputValue === '' ? null : Number(inputValue.replace(',', '.'));

  if (!isNumber(numberValue) || isNaN(numberValue)) {
    return isNumber(defaultValue)
      ? limitPercentageDecimalPlaces(limitValue(defaultValue) * 100)
      : defaultValue;
  }

  if (numberValue <= 0) {
    return 0;
  }

  if (numberValue > 100) {
    return 100;
  }

  return numberValue;
};

export const useThresholdInput = ({
  value,
  onChange,
  defaultValue,
}: HookArg) => {
  const [thresholdValue, setThresholdValue] = useState<string | number | null>(
    () =>
      isNumber(defaultValue)
        ? limitPercentageDecimalPlaces(limitValue(defaultValue) * 100)
        : defaultValue
  );

  useEffect(() => {
    if (value) {
      const newInputValue = isNumber(value)
        ? limitPercentageDecimalPlaces(value * 100)
        : value;

      if (Number(thresholdValue) !== newInputValue) {
        setThresholdValue(newInputValue);
      }
    }
  }, [setThresholdValue, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (val: number | string | null) => {
    if (val === '' || isFinite(Number(val))) {
      const newValue =
        val === '' ? null : limitFloatDecimalPlaces(Number(val) / 100);
      onChange(newValue);
    }
    setThresholdValue(val);
  };

  const handleInputBlur = ({
    target: { value: inputValue },
  }: React.FocusEvent<HTMLInputElement>) => {
    const numberValue = resolveValue({ inputValue, defaultValue });

    handleInputChange(numberValue);
  };

  return { handleInputChange, thresholdValue, handleInputBlur };
};
