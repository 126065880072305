import { IconAlertTriangleFilled } from '@rossum/ui/icons/tabler';
import { Stack, SvgIcon, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

const NetworkAlert = () => {
  const intl = useIntl();

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme => theme.palette.error.light,
        py: 0.5,
        height: '32px',
      }}
    >
      <SvgIcon fontSize="small">
        <IconAlertTriangleFilled />
      </SvgIcon>
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'components.announcements.networkAlert.text',
        })}
      </Typography>
    </Stack>
  );
};

export default NetworkAlert;
