import { alpha, Box } from '@rossum/ui/material';
import clsx from 'clsx';
import ListIcon from 'mdi-react/FormatListBulletedIcon';
import GridIcon from 'mdi-react/GridIcon';
import styles from '../styles.module.sass';

type Props = {
  amount: number;
  isSimpleMultivalue: boolean;
  active?: boolean;
};

const MultivalueBadge = ({ amount, isSimpleMultivalue, active }: Props) => (
  <div
    className={clsx(
      styles.MultivalueCollapsed,
      active && styles.MultiValueCollapsedActive
    )}
  >
    <Box
      component="span"
      sx={{
        fontFeatureSettings: '"zero"',
        verticalAlign: 'text-bottom',
        '&::selection': {
          backgroundColor: t => alpha(t.palette.action.hover, 0.2),
        },
      }}
    >
      {amount}
    </Box>

    {isSimpleMultivalue ? (
      <ListIcon className={styles.TypeIcon} />
    ) : (
      <GridIcon className={styles.TypeIcon} />
    )}
  </div>
);

export default MultivalueBadge;
