import { Box, Skeleton } from '@rossum/ui/material';
import clsx from 'clsx';
import { get, invoke } from 'lodash';
import { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect, DispatchProp } from 'react-redux';
import { mapLinebreaks } from '../../../lib/helpers';
import { shouldComponentUpdateDeepEqual } from '../../../lib/shouldComponentUpdateDeepEqual';
import { BboxParams } from '../../../lib/spaceConvertor';
import { DatapointAutomationBlocker } from '../../../redux/modules/annotation/types';
import {
  canDrawBbox,
  getUITypeFromSchema,
} from '../../../redux/modules/schema/helpers';
import { stopEditingDatapointValue } from '../../../redux/modules/ui/actions';
import {
  AnyDatapointDataST,
  DatapointValueDataST,
} from '../../../types/datapoints';
import { AnyDatapointSchema } from '../../../types/schema';
import DatapointTooltip, {
  getTooltipTypeWithHighestPriority,
} from '../../DatapointTooltip';
import styles from '../style.module.sass';
import Highlighter from './Highlighter';

const resolveRenderDrawBboxHint = (
  schema: AnyDatapointSchema | undefined,
  value: string,
  position: BboxParams | null | undefined,
  active: boolean,
  readOnly: boolean | undefined
) =>
  !!schema &&
  // hint should be rendered for 'captured' type and only when its value is empty
  getUITypeFromSchema(schema) === 'captured' &&
  !value &&
  !position &&
  // for both required and non-required fields it should be visible only when active
  active &&
  // in readOnly mode it doesn't make sense to render it
  !readOnly;

type Props = {
  active: boolean;
  data: DatapointValueDataST;
  documentAutomated: boolean;
  inFooter: boolean;
  onChange: (_val: string) => void;
  readOnly?: boolean;
  editingDatapointValue: boolean;
  currentColumn?: string;
  schema?: AnyDatapointSchema;
  columnSchemaId?: string;
  tickIconColor?: null | 'green' | 'white';
  datapointData?: AnyDatapointDataST;
  automationBlockers?: DatapointAutomationBlocker[];
  displayAutomationBlockers: boolean;
  setRef?: (input: HTMLInputElement | null) => void;
  isLoading?: boolean;
  intl: IntlShape;
  isEditable: boolean;
};

class Input extends Component<Props & DispatchProp> {
  input: HTMLInputElement | null = null;

  static defaultProps = { readOnly: false };

  shouldComponentUpdate(nextProps: Props, nextState: unknown): boolean {
    return shouldComponentUpdateDeepEqual(this, nextProps, nextState);
  }

  componentDidMount() {
    const { active } = this.props;
    if (active) {
      this.focusInput();
    }
  }

  // I did not realize any difference when this method was removed
  // Anyway was made restriction only for footer
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      active,
      editingDatapointValue,
      data: { position },
      readOnly,
    } = nextProps;

    const becameActive = active && !this.props.active;

    const didStopEditingDatapointValue =
      this.props.editingDatapointValue && !editingDatapointValue && active;

    const didRemoveBbox =
      active &&
      this.props.active &&
      !position &&
      this.props.data.position &&
      editingDatapointValue;

    const becameEditable = active && !readOnly && this.props.readOnly;

    const shouldFocus =
      (becameActive ||
        didStopEditingDatapointValue ||
        didRemoveBbox ||
        becameEditable) &&
      !nextProps.inFooter;

    if (shouldFocus) {
      this.focusInput(nextProps);
    }

    if (!active) invoke(this.input, 'blur');
  }

  focusInput = (props: Props = this.props) => {
    const {
      data: { position, value },
      inFooter,
    } = props;

    const cursorPosition = value.length;

    const shouldRenderDrawBboxHint = resolveRenderDrawBboxHint(
      props.schema,
      value,
      position,
      props.active,
      props.readOnly
    );

    if (
      (inFooter || !(canDrawBbox(props.schema) && position)) &&
      this.input &&
      !shouldRenderDrawBboxHint
    ) {
      this.input.focus();
      this.input.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  onChange = ({ target: { value } }: { target: { value: string } }) => {
    const { readOnly, onChange } = this.props;
    if (!readOnly) {
      onChange(mapLinebreaks(this.props.data.value, value));
    }
  };

  handleInputRef = (inputRef: HTMLInputElement | null) => {
    this.input = inputRef;

    if (this.props.setRef) {
      this.props.setRef(inputRef);
    }
    if (this.props.active) {
      this.focusInput();
    }
  };

  render() {
    const {
      active,
      columnSchemaId,
      currentColumn,
      data,
      documentAutomated,
      inFooter,
      readOnly,
      schema,
      tickIconColor,
      datapointData,
      automationBlockers,
      displayAutomationBlockers,
      isLoading,
      isEditable,
    } = this.props;

    const value = get(data, 'value');

    const escapedValue = value && value.replace(/\n/g, ' ');

    if (isLoading)
      return (
        <Skeleton
          width="100%"
          height="100%"
          sx={{ minWidth: '75px', flex: '1 1 0%' }}
        />
      );

    const shouldRenderDrawBboxHint = resolveRenderDrawBboxHint(
      schema,
      value,
      data.position,
      active,
      readOnly
    );

    const uiFieldType = getUITypeFromSchema(schema);

    return !!currentColumn && currentColumn === columnSchemaId ? (
      <Highlighter
        className={clsx(
          styles[`FakeInputDivType-${get(schema, 'type', '')}`],
          styles.FakeInputDiv
        )}
        value={escapedValue}
      />
    ) : (
      <>
        {inFooter && datapointData && (
          <DatapointTooltip
            data={datapointData}
            tickIconColor={tickIconColor}
            inFooter
            documentAutomated={documentAutomated}
            automationBlockers={automationBlockers}
            active={active}
            uiFieldType={uiFieldType}
            tooltipType={getTooltipTypeWithHighestPriority({
              displayAutomationBlockers,
              tickIconColor,
              uiFieldType,
            })}
          />
        )}
        {active && isEditable ? (
          <input
            autoComplete="off"
            className={clsx(
              styles[`InputType-${get(schema, 'type', '')}`],
              inFooter ? styles.ValueFooter : styles.ValueSidebar,
              shouldRenderDrawBboxHint && styles.ValueAsPlaceholder,
              readOnly && styles.InputReadOnly,
              active
                ? inFooter
                  ? styles.InputActiveFooter
                  : styles.InputActive
                : styles.InputInactive,
              !!tickIconColor &&
                styles[
                  `${
                    inFooter
                      ? 'ColorByValidationSourceFooter'
                      : 'ColorByValidationSource'
                  }-${tickIconColor}`
                ]
            )}
            style={{
              fontFeatureSettings: '"zero"',
            }}
            onBlur={() =>
              this.props.editingDatapointValue &&
              this.props.dispatch(stopEditingDatapointValue())
            }
            disabled={readOnly}
            onChange={this.onChange}
            ref={this.handleInputRef}
            type="text"
            value={escapedValue}
            onClick={e => {
              e.stopPropagation();
            }}
            placeholder={
              shouldRenderDrawBboxHint
                ? this.props.intl.formatMessage({
                    id: 'components.datapoint.drawBBoxHint',
                  })
                : ''
            }
          />
        ) : (
          <div
            className={clsx(
              styles[`InputType-${get(schema, 'type', '')}`],
              inFooter ? styles.ValueFooter : styles.ValueSidebar,
              shouldRenderDrawBboxHint && styles.ValueAsPlaceholder,
              styles.FakeValue,
              readOnly && styles.InputReadOnly,
              !isEditable && styles.InputDisabled,
              active
                ? inFooter
                  ? styles.InputActiveFooter
                  : styles.InputActive
                : styles.InputInactive,
              !!tickIconColor &&
                styles[
                  `${
                    inFooter
                      ? 'ColorByValidationSourceFooter'
                      : 'ColorByValidationSource'
                  }-${tickIconColor}`
                ]
            )}
          >
            <span className={styles.Value}>
              <Box sx={{ fontFeatureSettings: '"zero"' }}>
                {shouldRenderDrawBboxHint
                  ? this.props.intl.formatMessage({
                      id: 'components.datapoint.drawBBoxHint',
                    })
                  : escapedValue}
              </Box>
            </span>
          </div>
        )}
      </>
    );
  }
}

export default injectIntl<'intl', Props>(connect()(Input));
