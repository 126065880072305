import { SchemaRuleAction, SchemaSection } from '@rossum/api-client/schemas';
import { IconExclamationCircle } from '@rossum/ui/icons/tabler';
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { visibleActions } from '../actions';
import { ActionAutocomplete } from './ActionAutocomplete';
import { ActionFormActions } from './ActionFormActions';

type ActionFormProps = {
  action: SchemaRuleAction;
  index: number;
  remove: (index: number) => void;
  control: Control<{ ruleActions: SchemaRuleAction[] }, unknown>;
  schemaContent: SchemaSection[];
};

export const ActionForm = ({
  action,
  remove,
  index,
  control,
  schemaContent,
}: ActionFormProps) => {
  const intl = useIntl();

  // Newly created actions start as expanded
  const [expanded, setExpanded] = useState(action.action === undefined);

  return (
    <Controller
      control={control}
      name={`ruleActions.${index}.action`}
      render={({ field, fieldState }) => {
        const { value } = field;

        const actionDef = value
          ? visibleActions.find(visibleAction =>
              visibleAction.resolveAction(value)
            )
          : undefined;
        const Form = actionDef?.form;
        const Header = actionDef?.header;
        const Icon = actionDef?.icon;

        return (
          <Stack
            data-cy="action-tile"
            data-id={action.id}
            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
          >
            <ListItem
              dense
              sx={{ minHeight: 60 }}
              secondaryAction={
                <ActionFormActions
                  control={control}
                  expanded={expanded}
                  index={index}
                  setExpanded={value ? setExpanded : undefined}
                  remove={remove}
                />
              }
            >
              {Icon ? (
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
              ) : null}
              {actionDef ? (
                <ListItemText
                  onClick={() => setExpanded(prevExpanded => !prevExpanded)}
                  primary={
                    <Stack spacing={1} direction="row">
                      <Typography variant="h6">
                        {intl.formatMessage({
                          id: `features.queueSettings.rules.actions.${actionDef.intlKey}.name`,
                        })}
                      </Typography>
                      {fieldState.error ? (
                        <Tooltip
                          title={
                            <>
                              {/* @ts-expect-error This should be array because of criteriaMode: 'all' */}
                              {fieldState.error.map(e => (
                                <Typography key={e.message} variant="inherit">
                                  {e.message}
                                </Typography>
                              ))}
                            </>
                          }
                        >
                          <SvgIcon color="error">
                            <IconExclamationCircle />
                          </SvgIcon>
                        </Tooltip>
                      ) : null}
                    </Stack>
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    Header && field.value ? (
                      <Header value={field.value.payload} />
                    ) : undefined
                  }
                />
              ) : null}
              {!value ? (
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h6',
                    color: 'text.secondary',
                  }}
                  primary={intl.formatMessage({
                    id: 'features.queueSettings.rules.detail.then.newAction',
                  })}
                  secondary={intl.formatMessage({
                    id: 'features.queueSettings.rules.detail.then.newActionDescription',
                  })}
                />
              ) : null}
            </ListItem>
            <Collapse in={expanded}>
              <Stack direction="row" spacing={3} p={2} pt={1}>
                <Stack width="50%">
                  {actionDef ? (
                    <Stack spacing={1}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="span"
                      >
                        {intl.formatMessage({
                          id: `features.queueSettings.rules.actions.${actionDef.intlKey}.description`,
                        })}
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
                <Stack spacing={2} width="50%">
                  <ActionAutocomplete
                    value={field.value}
                    onChange={s => {
                      field.onChange(s);
                    }}
                  />
                  {Form ? (
                    <Form
                      value={field.value?.payload}
                      onChange={payload =>
                        field.onChange({ type: field.value?.type, payload })
                      }
                      schemaContent={schemaContent}
                    />
                  ) : null}
                </Stack>
              </Stack>
            </Collapse>
          </Stack>
        );
      }}
    />
  );
};
