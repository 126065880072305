import { Components, Theme } from '../../material';

export const muiTooltip = (theme: Theme): Components['MuiTooltip'] => ({
  defaultProps: {
    arrow: true,
    // All additional props passed to Tooltip are assigned to the children element.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    'data-tooltip-trigger': true,
  },
  // shadow and background are same as paper with elevation 24
  styleOverrides: {
    tooltip: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(10),
      textAlign: 'center',
      boxShadow:
        '0px 4px 6px 1px rgba(13, 17, 23, 0.18), 0px 4px 10px 0px rgba(13, 17, 23, 0.06)',
      backgroundColor: theme.palette.other.tooltip,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    arrow: {
      color: theme.palette.other.tooltip,
    },
  },
});
