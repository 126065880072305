import { get } from 'lodash';
import { CSSProperties, ReactNode } from 'react';
import { uiFieldTypeIconsMap } from '../../redux/modules/schema/helpers';
import { OriginalDatapointSchema, UiFieldType } from '../../types/schema';

type Props = Pick<
  OriginalDatapointSchema,
  'width' | 'label' | 'stretch' | 'type'
> & { uiFieldType: UiFieldType };

type ComponentProps = {
  children: ReactNode;
  className?: string;
} & Props;

const PADDING = 41;
const CHAR_WIDTH_IN_NEW_FOOTER = 9;
const ICON_WIDTH = 28;

interface Defaults {
  [key: string]: (_label: string) => number;
}

const defaults: Defaults = {
  enum: () => 20,
  number: () => 8,
  string: () => 20,
  date: () => 10,
  button: (label: string) => label.length,
};
export const calculateWidth = ({
  width,
  type,
  label,
  uiFieldType,
}: Omit<Props, 'stretch'>) => {
  const iconWidth =
    uiFieldType && uiFieldTypeIconsMap[uiFieldType] ? ICON_WIDTH : 0;
  const sizeAddition = iconWidth + PADDING;

  return width
    ? width * CHAR_WIDTH_IN_NEW_FOOTER + sizeAddition
    : get(defaults, type, defaults.string)(label) * CHAR_WIDTH_IN_NEW_FOOTER +
        sizeAddition;
};

const getCustomStyle = ({
  width,
  type,
  stretch,
  label,
  uiFieldType,
}: Props): CSSProperties => ({
  minWidth: calculateWidth({ width, type, label, uiFieldType }),
  maxWidth: stretch
    ? undefined
    : calculateWidth({ width, type, label, uiFieldType }),
  textAlign: type === 'number' ? 'right' : 'left',
});

const FooterCell = ({
  className,
  children,
  width,
  stretch,
  label,
  type,
  uiFieldType,
}: ComponentProps) => (
  <div
    style={{
      ...getCustomStyle({ width, stretch, label, type, uiFieldType }),
      flex: '1 1 0',
    }}
    className={className}
  >
    {children}
  </div>
);

export default FooterCell;
