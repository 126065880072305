import { withTheme } from '@emotion/react';
import { Message } from '@rossum/api-client/shared';
import { Box, Stack, Theme, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { get, invoke, isEmpty } from 'lodash';
import React, { Component, ReactNode, RefObject } from 'react';
import { DispatchProp } from 'react-redux';
import * as R from 'remeda';
import { isTruthy } from 'remeda';
import { Subject } from 'rxjs';
import { isEmbedded } from '../../constants/config';
import SelectInputFooter from '../../containers/SelectInputFooter';
import SelectInputSidebar from '../../containers/SelectInputSidebar';
import refTracker, {
  ConnectedProps,
} from '../../decorators/DatapointRefTracker';
import { getFromUnion } from '../../lib/helpers';
import { DatapointAutomationBlocker } from '../../redux/modules/annotation/types';
import {
  selectDatapoint,
  updateDatapointValue,
} from '../../redux/modules/datapoints/actions';
import { updateSuggestedOperationPositionAction } from '../../redux/modules/datapoints/suggestedOperations/actions';
import { getBorderColor } from '../../redux/modules/datapoints/suggestedOperations/utils';
import { isVirtualDatapoint } from '../../redux/modules/datapoints/typedHelpers';
import {
  getUIEditabilityFromSchema,
  getUITypeFromSchema,
  isFieldEditable,
  isFieldSelectable,
} from '../../redux/modules/schema/helpers';
import { startEditingDatapointValue } from '../../redux/modules/ui/actions';
import { updateUiSettings } from '../../redux/modules/user/actions';
import {
  MatchedTriggerRuleDatapoint,
  SimpleDatapointDataST,
  SuggestedOperationWithMeta,
} from '../../types/datapoints';
import { AnyDatapointSchema, ButtonDatapointSchema } from '../../types/schema';
import ButtonDatapoint from '../ButtonDatapoint';
import DatapointTooltip, {
  getTooltipTypeWithHighestPriority,
} from '../DatapointTooltip';
import FooterCell from '../footerCell';
import DatapointMessage from './components/DatapointMessage';
import DragHandler from './components/DragHandler';
import { OnValueChangeOptions } from './components/Highlighter';
import Input from './components/Input';
import MultiValue from './components/MultiValue';
import SoftWarning from './components/SoftWarning';
import UiFieldTypeMessage from './components/UiFieldTypeMessage';
import {
  getLoggableDatapointValue,
  isDatapointEditedFormulaField,
  isFieldEditableFormula,
  validationTickColor,
} from './helpers';
import styles from './style.module.sass';

export const scrollToDatapoint = new Subject();
export type LastInteractionT = 'scroll' | 'navigation';

export type OwnProps = {
  automationBlockers?: DatapointAutomationBlocker[];
  matchedRules?: MatchedTriggerRuleDatapoint;
  childSelected?: boolean;
  currentColumn?: string;
  datapointIndex?: number;
  documentAutomated: boolean;
  editingDatapointValue: boolean;
  endDrag?: () => void;
  hideDragger?: boolean;
  inFooter: boolean;
  index?: number;
  isLoading?: boolean;
  isSimpleMultivalue: boolean;
  message: Message | null | undefined;
  messages?: { [key: number]: Message };
  myPath: Array<number>;
  readOnly: boolean;
  resizing?: boolean;
  schema?: AnyDatapointSchema;
  searchPanelShown: boolean;
  setRef?: RefObject<HTMLDivElement>;
  startDrag?: () => void;
  suggestedOperation?: SuggestedOperationWithMeta;
  suggestedOperationsSchemaIds?: string[];
  lastInteraction?: LastInteractionT;
  handleLastInteraction?: (interactionAction: LastInteractionT) => void;
  hideCapturedSoftWarning?: boolean;
  hideDataSoftWarning?: boolean;
  formulaValidationInProgress?: boolean;
  isGhostRow?: boolean;
};

type Props = OwnProps &
  ConnectedProps &
  DispatchProp & {
    // TODO: Refactor when select is rewritten to MUI
    theme: Theme;
  };

const Div = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => {
  return <div className={className}>{children}</div>;
};

const StickyDiv = (
  props: {
    sticky: boolean;
    isSimpleMultivalue: boolean;
    className: string;
    isActive: boolean;
  } & React.Attributes
) => {
  const { sticky } = props;

  return React.cloneElement(<Div />, {
    ...props,
    className: clsx(
      props.className,
      !props.isSimpleMultivalue && styles.ActiveWrapper,
      sticky && styles.StickyWrapper
    ),
  });
};

class Datapoint extends Component<Props> {
  input: HTMLInputElement | null = null;

  justClicked = false;

  sticky = false;

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const becameActive = nextProps.active && !this.props.active;

    // A newly activated datapoint should never be sticky,
    // because the sidebar will be scrollled to show it.
    if (becameActive) {
      this.sticky = false;
      return;
    }

    // Whenever the user starts scrolling,
    // the datapoint switches to sticky, and stays like that.
    if (
      nextProps.lastInteraction === 'scroll' &&
      this.props.lastInteraction !== 'scroll'
    ) {
      this.sticky = true;
    }
  }

  componentDidUpdate({ searchPanelShown, active: prevActive }: Props) {
    const { active, inFooter } = this.props;

    const focusOnSearchPanelClose = [
      !this.props.searchPanelShown,
      searchPanelShown,
      active,
    ].every(isTruthy);

    if (!inFooter && active && !prevActive) {
      scrollToDatapoint.next();
    }

    if (focusOnSearchPanelClose && this.input) {
      invoke(this.input, 'focusInput');
    }
  }

  onValueChange = (value: string, options?: OnValueChangeOptions) => {
    const { data } = this.props;

    if (data && !!this.props.suggestedOperation) {
      this.props.dispatch(
        updateSuggestedOperationPositionAction(data.id, {
          content: {
            value,
          },
        })
      );

      return;
    }

    if (data)
      this.props.dispatch(
        updateDatapointValue(
          {
            id: data.id,
            index: data.meta.index,
            validationSource: options?.setHumanValidationSource ? 'human' : '',
            oldValue: getLoggableDatapointValue(data),
            reason: isFieldEditableFormula(this.props.schema)
              ? 'manual-formula-override-sidebar'
              : 'input-sidebar',
            noRecalculation:
              isFieldEditableFormula(this.props.schema) || undefined,
          },
          value
        )
      );
  };

  handleDoubleClick = () => {
    if (this.justClicked && !this.props.readOnly) {
      this.justClicked = false;
      this.props.dispatch(startEditingDatapointValue());
    } else {
      this.justClicked = true;
      setTimeout(() => {
        this.justClicked = false;
      }, 500);
    }
  };

  onClick = () => {
    const { schema, active, myPath, handleLastInteraction, childSelected } =
      this.props;

    if (get(schema, 'type') !== 'button') {
      this.handleDoubleClick();
      if (!active || childSelected) {
        this.props.dispatch(selectDatapoint(myPath, { noTail: true }));

        if (handleLastInteraction) {
          handleLastInteraction('navigation');
        }
      }
    }
  };

  isOptionalSelect = (options: { value: string; label: string }[]) => {
    const constraints = get(this.props.schema, 'constraints');
    const hasEmptyValue = options.some(o => o.value === '');

    return !hasEmptyValue && !(constraints?.required ?? true);
  };

  render() {
    const {
      active,
      automationBlockers,
      childSelected,
      currentColumn,
      data,
      documentAutomated,
      editingDatapointValue,
      endDrag,
      hideDragger,
      inFooter,
      isSimpleMultivalue,
      matchedRules,
      message,
      messages,
      myPath,
      readOnly,
      resizing,
      schema,
      setRef,
      startDrag,
      suggestedOperation,
      suggestedOperationsSchemaIds,
      theme,
      isLoading,
      formulaValidationInProgress,
      dispatch,
      hideCapturedSoftWarning,
      hideDataSoftWarning,
      isGhostRow,
    } = this.props;

    const uiFieldType = getUITypeFromSchema(schema);
    const isEditable = isFieldEditable(schema);
    const editability = getUIEditabilityFromSchema(schema);
    const isEditedFormula = isDatapointEditedFormulaField(data, schema);

    const isNonSelectable = !isFieldSelectable(schema);

    const isSelect = get(schema, 'type') === 'enum';
    const isButton = get(schema, 'type') === 'button';
    // Removed check for position because you can still edit cell in footer if it has a bbox, the border just didn't show
    const isEditingValue = inFooter && active && editingDatapointValue;

    const isDatapointFromAddOperation =
      isVirtualDatapoint(data.id) &&
      !!(data as SimpleDatapointDataST).content?.value &&
      !isGhostRow;

    const operationSource = isDatapointFromAddOperation
      ? ('table' as const)
      : suggestedOperation
        ? suggestedOperation.source
        : undefined;

    const classNames = clsx(
      isEditingValue && styles.Editing,
      inFooter ? styles.FooterDatapoint : styles.Datapoint,
      active &&
        (inFooter
          ? data.category !== 'multivalue' && styles.ActiveHighlight
          : clsx(
              styles.Active,
              (readOnly || isNonSelectable) && styles.ReadOnlyActive
            )),
      active &&
        data.category === 'multivalue' &&
        styles.SidebarMultivalueDatapoint,
      isSelect &&
        !inFooter &&
        !(readOnly || !isEditable) &&
        clsx(
          styles.SelectSidebarDatapoint,
          active && styles.SelectSidebarDatapointActive
        ),
      operationSource &&
        (operationSource === 'table' ? styles.SuggestedV2 : styles.Suggested),

      'Datapoint'
    );

    const displayAutomationBlockers = !!automationBlockers;

    const tickIconColor = validationTickColor(
      data,
      active,
      !!suggestedOperation,
      message?.type === 'error'
    );

    const displaySources =
      data.category !== 'multivalue' || !!data.children.length;

    const borderColor =
      operationSource === 'table'
        ? theme.palette.aurora.main
        : getBorderColor(
            suggestedOperation?.meta.datapoint.schemaId,
            suggestedOperationsSchemaIds
          );

    const style = {
      borderColor,
    };

    const Wrapper = inFooter ? FooterCell : active ? StickyDiv : Div;
    const label = get(schema, 'label');
    const columnSchemaId = get(schema, 'id');
    const showLabel = !inFooter && label && !isButton;
    const displayDragHandler =
      !hideDragger && inFooter && active && !message && !readOnly;

    const options =
      getFromUnion(data, 'options') || getFromUnion(schema, 'options') || [];

    const isClearable = this.isOptionalSelect(options);

    const simpleMultivalueChildIsSelected = isSimpleMultivalue && childSelected;

    const softWarningCanBeShown = !inFooter && !readOnly;

    if (!data || isEmpty(schema)) {
      return null;
    }

    return (
      <Wrapper
        width={get(schema, 'width')}
        label={get(schema, 'label')}
        type={get(schema, 'type')}
        stretch={get(schema, 'stretch')}
        className="sectionWrapper"
        isSimpleMultivalue={active && isSimpleMultivalue}
        isActive={active}
        sticky={this.sticky}
        uiFieldType={uiFieldType ?? null}
      >
        <Box
          id={
            active && !inFooter && data.category !== 'multivalue'
              ? 'magic-line-source'
              : undefined
          }
          sx={
            operationSource === 'table'
              ? {
                  color: t => t.palette.text.secondary,
                }
              : undefined
          }
          data-cy="annotation-sidebar-datapoint"
          data-sa-extension-schema-id={data.schemaId}
          ref={simpleMultivalueChildIsSelected ? null : setRef}
          style={style}
          className={classNames}
          onClick={this.onClick}
        >
          <DatapointMessage
            hideBorder={isEditingValue || !!suggestedOperation}
            isButton={isButton}
            message={message ?? undefined}
            active={active}
            resizing={resizing}
            inFooter={inFooter}
          >
            {({ messageIcon: _messageIcon }) => (
              <>
                {!inFooter && (
                  <Stack
                    direction="row"
                    spacing={0.5}
                    mr={1}
                    sx={{ minWidth: 21 }}
                  >
                    {_messageIcon}
                    <DatapointTooltip
                      data={data}
                      active={active}
                      automationBlockers={automationBlockers}
                      matchedRules={matchedRules}
                      documentAutomated={documentAutomated}
                      tickIconColor={tickIconColor}
                      tooltipType={getTooltipTypeWithHighestPriority({
                        displayAutomationBlockers,
                        tickIconColor,
                        displaySources,
                      })}
                      uiFieldType={uiFieldType}
                    />
                  </Stack>
                )}
                <UiFieldTypeMessage
                  uiFieldType={inFooter || isEditedFormula ? null : uiFieldType}
                  readOnly={readOnly}
                  fieldEditable={isEditable}
                >
                  {showLabel && (
                    <Typography
                      variant={
                        isSelect && active && !(readOnly || !isEditable)
                          ? 'body2'
                          : 'body1'
                      }
                      sx={{
                        mr:
                          isSelect && active && !(readOnly || !isEditable)
                            ? 0
                            : 1.25,
                        color: t =>
                          t.palette.mode === 'light'
                            ? t.palette.common.black
                            : '',
                      }}
                      className={clsx(
                        isSelect
                          ? active && !(readOnly || !isEditable)
                            ? styles.SelectTitleActive
                            : styles.SelectTitlePassive
                          : styles.Title
                      )}
                    >
                      {label}
                    </Typography>
                  )}
                  {(() => {
                    switch (data.category) {
                      case 'multivalue':
                        return (
                          <MultiValue
                            editingDatapointValue={editingDatapointValue}
                            active={active}
                            multivalueData={data}
                            documentAutomated={documentAutomated}
                            maxOccurrences={
                              get(schema, 'maxOccurrences') ?? undefined
                            }
                            minOccurrences={
                              get(schema, 'minOccurrences') ?? undefined
                            }
                            myPath={myPath}
                            messages={messages}
                            readOnly={readOnly}
                            setRef={
                              simpleMultivalueChildIsSelected
                                ? setRef ?? null
                                : null
                            }
                            childrenSchemaId={get(schema, 'children[0]')}
                            isSimpleMultivalue={isSimpleMultivalue}
                          />
                        );
                      case 'datapoint': {
                        switch (get(schema, 'type')) {
                          case 'button':
                            return (
                              <ButtonDatapoint
                                schema={schema as ButtonDatapointSchema}
                                data={data}
                                inFooter={inFooter}
                              />
                            );
                          case 'enum': {
                            // If value of enum is not found, it has value === '', but there could be
                            // an option with empty string value in the options as well.
                            // We want to set this option instead of fallbacking to default value if the empty value option is available
                            const isEmptyStringInOptions = options.some(
                              option => option.value === ''
                            );

                            const value = R.find(options, option => {
                              const resolvedValue =
                                data.content?.value !== undefined &&
                                data.content.value !== null &&
                                (data.content.value !== '' ||
                                  isEmptyStringInOptions)
                                  ? data.content.value
                                  : data.schema?.defaultValue ?? '';

                              return (
                                R.toLowerCase(option.value) ===
                                R.toLowerCase(resolvedValue)
                              );
                            });

                            return (
                              <>
                                {inFooter && (
                                  <DatapointTooltip
                                    data={data}
                                    active={active}
                                    automationBlockers={automationBlockers}
                                    matchedRules={matchedRules}
                                    tickIconColor={tickIconColor}
                                    documentAutomated={documentAutomated}
                                    inFooter
                                    tooltipType={getTooltipTypeWithHighestPriority(
                                      {
                                        displayAutomationBlockers,
                                        tickIconColor,
                                        uiFieldType,
                                      }
                                    )}
                                  />
                                )}
                                {inFooter ? (
                                  <SelectInputFooter
                                    clearable={isClearable}
                                    readOnly={readOnly || !isEditable}
                                    onChange={this.onValueChange}
                                    options={options}
                                    style={
                                      matchedRules
                                        ? {
                                            color: theme.palette.warning.main,
                                          }
                                        : undefined
                                    }
                                    active={active}
                                    value={value}
                                  />
                                ) : (
                                  <SelectInputSidebar
                                    clearable={isClearable}
                                    readOnly={readOnly || !isEditable}
                                    onChange={this.onValueChange}
                                    options={options}
                                    active={active}
                                    style={
                                      matchedRules
                                        ? {
                                            color: theme.palette.warning.main,
                                          }
                                        : undefined
                                    }
                                    value={value}
                                  />
                                )}
                              </>
                            );
                          }
                          default:
                            return (
                              <Input
                                isLoading={
                                  isLoading || formulaValidationInProgress
                                }
                                inFooter={inFooter}
                                active={active}
                                data={{
                                  value: '',
                                  page: null,
                                  position: null,
                                  ...data.content,
                                  ...get(
                                    suggestedOperation,
                                    ['value', 'content'],
                                    {}
                                  ),
                                }}
                                displayAutomationBlockers={
                                  displayAutomationBlockers
                                }
                                documentAutomated={documentAutomated}
                                automationBlockers={automationBlockers}
                                columnSchemaId={columnSchemaId}
                                editingDatapointValue={editingDatapointValue}
                                onChange={this.onValueChange}
                                currentColumn={currentColumn}
                                readOnly={readOnly}
                                setRef={(ref: HTMLInputElement | null) => {
                                  this.input = ref;
                                }}
                                schema={schema}
                                tickIconColor={tickIconColor}
                                isEditable={isEditable}
                                datapointData={data}
                              />
                            );
                        }
                      }
                      default:
                        return null;
                    }
                  })()}
                </UiFieldTypeMessage>
              </>
            )}
          </DatapointMessage>
          {displayDragHandler && startDrag && endDrag && (
            <DragHandler startDrag={startDrag} endDrag={endDrag} />
          )}
        </Box>
        <Stack sx={{ ':not(:empty)': { mb: 1 } }}>
          {((uiFieldType === 'captured' &&
            !hideCapturedSoftWarning &&
            !isSelect &&
            editability !== 'enabled_without_warning') ||
            (uiFieldType === 'data' &&
              !hideDataSoftWarning &&
              editability === 'enabled')) && (
            <SoftWarning
              active={active && !isEmbedded() && softWarningCanBeShown}
              textKey={`components.datapoint.warning.edit.text.${uiFieldType}`}
              buttonKey="components.datapoint.warning.edit.button"
              textKeySecondary={
                uiFieldType === 'captured'
                  ? 'components.datapoint.warning.edit.text.captured.shortcut'
                  : undefined
              }
              handleOnClick={() =>
                dispatch(
                  updateUiSettings({
                    hideWarningEditableFields: {
                      [uiFieldType]: true,
                    },
                  })
                )
              }
              dataCy={`datapoint-hide-warning-editable-${uiFieldType}-fields`}
            />
          )}
        </Stack>
      </Wrapper>
    );
  }
}

export default refTracker(withTheme(Datapoint));
