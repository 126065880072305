import { IconAlertTriangleFilled } from '@rossum/ui/icons/tabler';
import {
  Button,
  CircularProgress,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { RepeatedStatus } from '../../../redux/modules/ui/types';

const Loading = () => {
  const intl = useIntl();

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme => theme.palette.grey[700],
        py: 0.5,
        height: '32px',
      }}
    >
      <CircularProgress size={20} color="inherit" />
      <Typography variant="body2">
        {intl.formatMessage({ id: 'components.announcements.updating.text' })}
      </Typography>
    </Stack>
  );
};

const Refresh = () => {
  const intl = useIntl();

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme => theme.palette.error.light,
        py: 0.5,
        height: '32px',
      }}
    >
      <SvgIcon fontSize="small">
        <IconAlertTriangleFilled />
      </SvgIcon>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {intl.formatMessage({ id: 'components.announcements.refresh.title' })}
      </Typography>
      <Typography variant="body2">
        {intl.formatMessage({ id: 'components.announcements.refresh.text' })}
      </Typography>
      <Button
        color="inherit"
        size="small"
        onClick={() => window.location.reload()}
      >
        <FormattedMessage id="components.announcements.refresh.confirm" />
      </Button>
    </Stack>
  );
};

const RepeatedStatusAlert = ({ status }: { status: RepeatedStatus }) =>
  status === 'pending' ? <Loading /> : <Refresh />;

export default RepeatedStatusAlert;
