import { IconUserFilled, IconUserMinus } from '@rossum/ui/icons/tabler';
import {
  Box,
  Button,
  Dialog,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ContentWrapper from '../../components/ContentWrapper/index';
import PageLayout from '../../components/PageLayout/index';
import { DEV_FEATURES_ENABLED } from '../../constants/config';
import { getRoleName } from '../../redux/modules/groups/helpers';
import {
  PartialUser,
  updateUser as updateUserAction,
} from '../../redux/modules/user/actions';
import { getName } from '../../redux/modules/user/helpers';
import { GroupRole } from '../../types/group';
import { State } from '../../types/state';
import { User } from '../../types/user';
import FeatureFlagToggle from '../../unleash/FeatureFlagToggle';
import DeleteUserDialog from '../Users/components/DeleteUser/DeleteUserDialog';
import Section from './components/Section';
import ChangePasswordForm from './containers/ChangePasswordForm';
import PersonalInformationForm from './containers/PersonalInformationForm';
import Sidebar from './containers/Sidebar';

type OwnProps = {
  user: User;
  role?: GroupRole;
  updateUser: (_payload: PartialUser, _meta: object) => void;
} & RouteComponentProps<{ section: string }>;
type IntlProps = { intl: IntlShape };

type Props = OwnProps & IntlProps;

const PersonalInfo = ({
  updateUser,
  user,
  role,
  intl,
  match: {
    params: { section },
  },
  location: { key },
}: Props) => {
  const [open, setOpen] = useState(false);

  const activeRoute = { section, key };

  return (
    <PageLayout data-page-title="personal-info">
      <Sidebar />
      <ContentWrapper>
        <Stack
          spacing={1}
          sx={{
            alignItems: 'center',
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Stack spacing={3} sx={{ maxWidth: '960px', p: 3, pt: 6 }}>
            <Stack direction="row" spacing={1} sx={{ width: '960px' }}>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '100%',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme => theme.palette.grey['700'],
                  }}
                >
                  <SvgIcon fontSize="small" color="primary">
                    <IconUserFilled />
                  </SvgIcon>
                </Box>
                <Stack spacing={0.5}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {getName(user)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user.email || user.username}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Stack spacing={8}>
                <Section
                  title="containers.personalInfo.personalInformation"
                  id="personalInfo"
                  activeRoute={activeRoute}
                >
                  <PersonalInformationForm
                    updateUser={updateUser}
                    intl={intl}
                    user={user}
                  />
                </Section>
                <Section
                  title="containers.personalInfo.changePassword"
                  id="changePassword"
                  activeRoute={activeRoute}
                >
                  <ChangePasswordForm username={user.username} />
                </Section>

                {DEV_FEATURES_ENABLED ? (
                  <Section
                    title="containers.personalInfo.changeFeatureFlags"
                    id="changeFeatureFlags"
                    activeRoute={activeRoute}
                  >
                    <FeatureFlagToggle />
                  </Section>
                ) : null}
              </Stack>

              <Button
                variant="outlined"
                data-cy="delete-account-button"
                color="error"
                onClick={() => setOpen(true)}
                sx={{ mt: 4, alignSelf: 'flex-start' }}
                startIcon={
                  <SvgIcon fontSize="small">
                    <IconUserMinus />
                  </SvgIcon>
                }
              >
                {intl.formatMessage({
                  id: 'containers.personalInfo.deleteAccount.button',
                })}
              </Button>
              <Dialog
                open={open}
                PaperProps={{
                  sx: { width: 480, minHeight: 213, position: 'fixed' },
                  elevation: 2,
                }}
                onClose={() => setOpen(false)}
                sx={{ transition: 'smooth' }}
              >
                {open ? (
                  <DeleteUserDialog
                    user={{ ...user, role }}
                    onCancel={() => setOpen(false)}
                  />
                ) : null}
              </Dialog>
            </Stack>
          </Stack>
        </Stack>
      </ContentWrapper>
    </PageLayout>
  );
};

const mapStateToProps = (state: State) => {
  const { user } = state;
  const role = getRoleName(user.groups, state.groups);

  return { user, role };
};

const mapDispatchToProps = {
  updateUser: updateUserAction,
};

export default injectIntl<'intl', OwnProps & IntlProps>(
  connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
);
