import { Queue } from '@rossum/api-client/queues';
import {
  Slider,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import {
  limitPercentageDecimalPlaces,
  resolveValue,
} from '../../../../components/UI/Threshold/useThresholdInput';

const DEFAULT_VALUE = 0.8;

const predefinedValues = [
  { value: 0, label: '0 %' },
  { value: 0.65, label: '65 %' },
  { value: DEFAULT_VALUE, label: '80 %' },
  { value: 0.975, label: '97.5 %' },
  { value: 1, label: '100 %' },
];

// While the user didn't leave the custom input, the value could be incorrect
// so we need to do handle it here
export const clampThresholdValue = (value: number) =>
  Math.max(0, Math.min(value, 1));

export const getRatio = (value: number) =>
  limitPercentageDecimalPlaces(100 - value * 100);

const DefaultScoreThreshold = ({
  control,
  defaultValue,
}: {
  control: Control<Pick<Queue, 'defaultScoreThreshold' | 'automationLevel'>>;
  defaultValue: number;
}) => {
  const intl = useIntl();

  const {
    field: { value: thresholdValue, onChange: thresholdOnChange },
  } = useController({ name: 'defaultScoreThreshold', control });

  const [isCustom, setIsCustom] = useState(
    !predefinedValues.some(option => option.value === thresholdValue)
  );

  const [textFieldValue, setTextFieldValue] = useState<string | number | null>(
    () =>
      R.isNumber(defaultValue)
        ? limitPercentageDecimalPlaces(
            R.clamp(defaultValue, { min: 0, max: 1 }) * 100
          )
        : defaultValue
  );

  const handleInputChange = useCallback(
    (val: number | string | null) => {
      if (val === '' || isFinite(Number(val))) {
        const newValue =
          val === '' ? null : parseFloat((Number(val) / 100).toFixed(3));
        thresholdOnChange(newValue);
      }
      setTextFieldValue(val);
    },
    [thresholdOnChange]
  );

  useEffect(() => {
    if (thresholdValue) {
      const newInputValue = R.isNumber(thresholdValue)
        ? limitPercentageDecimalPlaces(thresholdValue * 100)
        : thresholdValue;

      if (Number(thresholdValue) !== newInputValue) {
        setTextFieldValue(newInputValue);
      }
    }
  }, [setTextFieldValue, thresholdValue]);

  return (
    <Stack gap={2}>
      <ToggleButtonGroup
        value={isCustom ? 'custom' : thresholdValue}
        onChange={(_e, value) => thresholdOnChange(value)}
        exclusive
        size="large"
      >
        {predefinedValues.map(({ value, label }) => (
          <ToggleButton
            key={value}
            value={value}
            onClick={() => {
              setIsCustom(false);
            }}
          >
            {label}
          </ToggleButton>
        ))}
        <ToggleButton
          value="custom"
          onClick={e => {
            e.preventDefault();
            setIsCustom(true);
          }}
        >
          {intl.formatMessage({ id: 'components.threshold.custom' })}
        </ToggleButton>
      </ToggleButtonGroup>
      {isCustom ? (
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            0
          </Typography>
          <Slider
            value={thresholdValue}
            step={0.01}
            max={1}
            onChange={(_, val) =>
              thresholdOnChange(Array.isArray(val) ? val[0] : val)
            }
            sx={{ maxWidth: 240 }}
          />
          <Typography variant="body2" color="text.secondary">
            100%
          </Typography>
          <TextField
            size="small"
            // Since percentages are limited to one decimal place, user shouldn't be able to enter more than 4 characters
            slotProps={{
              htmlInput: { maxLength: 4, size: 4 },
              input: {
                endAdornment: (
                  <Typography variant="body2" color="text.secondary">
                    %
                  </Typography>
                ),
              },
            }}
            onBlur={e => {
              const inputValue = e.target.value;

              const numberValue = resolveValue({ inputValue, defaultValue });

              handleInputChange(numberValue);
            }}
            onChange={e => {
              const val = e.target.value;
              if (val === '' || isFinite(Number(val))) {
                const newValue =
                  val === ''
                    ? null
                    : parseFloat((Number(val) / 100).toFixed(3));
                thresholdOnChange(newValue);
              }
              setTextFieldValue(val);
            }}
            value={`${textFieldValue}`}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default DefaultScoreThreshold;
