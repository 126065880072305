import { Check, InfoOutlined } from '@mui/icons-material';
import {
  Grow,
  LinearProgress,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

type PasswordStrengthProgressProps = {
  value: string;
  messages: Array<string>;
  progress: number;
  scoreTranslation: string;
  scoreColor: 'error' | 'warning' | 'success';
};

export const PasswordStrengthProgress = ({
  value,
  messages,
  progress,
  scoreTranslation,
  scoreColor,
}: PasswordStrengthProgressProps) => {
  const color = scoreColor;

  const errorMessages = messages.map(message => (
    <ListItem key={message} sx={{ py: 0, pl: 0, display: 'list-item' }}>
      {message}
    </ListItem>
  ));

  return (
    <Grow
      in={value.length > 0}
      style={{
        transformOrigin: 'center left',
      }}
    >
      <Stack spacing={1} alignItems="end">
        <LinearProgress
          variant="determinate"
          value={Math.min(progress + value.length, 100) || 0}
          color={color}
          sx={{
            width: '100%',
            borderRadius: 5,
            height: value.length > 0 ? 4 : 0,
            transition: 'height 200',
            backgroundColor: theme => theme.palette.background.default,
          }}
        />

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            display: value === '' ? 'none' : 'flex',
          }}
        >
          {color === 'success' && <Check fontSize="small" color="success" />}
          <Typography variant="body2" color={`${color}.main`}>
            {scoreTranslation || ''}
          </Typography>
          {color !== 'success' && errorMessages.length > 0 && (
            <Tooltip
              title={
                <List sx={{ my: 0, ml: 2.5, listStyleType: 'disc' }}>
                  {errorMessages}
                </List>
              }
            >
              <InfoOutlined fontSize="small" color={color} />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Grow>
  );
};
